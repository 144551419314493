import React from "react";
import Grid from "@mui/material/Grid";
import CircleIcon from "../CircleIcon/CircleIcon";
import iconPdf from "../../assets/icon/picture_as_pdf-24px.svg";
import iconAttachment from "../../assets/icon/attach_file-24px.svg";
import iconImage from "../../assets/icon/collections-24px.svg";
import moment from "moment";
import { getMediaObjectSize } from "../../services/formServiceFunctions";
import DisplayOrDownloadMediaObjectWrapper from "../ElectronicFileFolder/DisplayOrDownloadMediaObjectWrapper";
import { Hidden, IconButton, Stack, Typography } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import { MediaObject } from "../../types/MediaObject";
import { ExternalReferenceFile } from "../../types/ExternalReferenceFile";

type ReferenceFileMediaObjectProps = {
  mediaObject: ExternalReferenceFile["mediaObjects"][number];
  setMediaObjectToDisplay: (mediaObject: MediaObject | null) => void;
  downloadRef?: React.Ref<HTMLAnchorElement>;
};
const ReferenceFileMediaObject = ({
  mediaObject,
  setMediaObjectToDisplay,
  downloadRef,
}: ReferenceFileMediaObjectProps) => {
  const getIcon = (mimeType: MediaObject["mimeType"]) => {
    switch (mimeType) {
      case "image/jpg":
      case "image/jpeg":
      case "image/png":
        return iconImage;
      case "application/pdf":
        return iconPdf;
      default:
        return iconAttachment;
    }
  };

  const createdAt = mediaObject.selectionDate ? moment(mediaObject.selectionDate) : null;

  return (
    <Stack direction={"row"}>
      <Hidden only={"xs"}>
        <Grid item sx={{ paddingRight: "2rem" }}>
          <CircleIcon icon={getIcon(mediaObject.mimeType)} />
        </Grid>
      </Hidden>
      <Grid container>
        <Grid item xs={12}>
          <DisplayOrDownloadMediaObjectWrapper
            mediaObject={mediaObject}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          >
            <Typography sx={{ color: "#317ae2", cursor: "pointer" }}>{mediaObject.originalName}</Typography>
          </DisplayOrDownloadMediaObjectWrapper>
        </Grid>
        <Grid item xs={12}>
          <Typography>{mediaObject.description}</Typography>
        </Grid>
        {createdAt && (
          <Grid item xs={12}>
            Hinzugefügt am {createdAt.format("DD.MM.YYYY")} um {createdAt.format("HH:mm")} Uhr |{" "}
            {getMediaObjectSize(mediaObject.size)}
          </Grid>
        )}
      </Grid>
      <IconButton
        component="a"
        download={mediaObject.originalName}
        href={mediaObject.downloadUrl}
        sx={{ width: "40px", height: "40px" }}
        ref={downloadRef}
      >
        <FileDownloadOutlined />
      </IconButton>
    </Stack>
  );
};

export default ReferenceFileMediaObject;

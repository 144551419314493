import React, { useEffect } from "react";

import { mimeTypes } from "../../services/Files/fileService";
import { MediaObject } from "../../types/MediaObject";
import DisplayMediaObjectContentPdf from "./DisplayMediaObjectContentPdf";
import DisplayMediaObjectContentImage from "./DisplayMediaObjectContentImage";
import DisplayMediaObjectContentAudio from "./DisplayMediaObjectContentAudio";
import DisplayMediaObjectContentVideo from "./DisplayMediaObjectContentVideo";
import { apiGet } from "../../services/Api/apiCall";
import { useState } from "react";
import { displayMediaObjectStyles } from "./displayMediaObjectStyle";
import { Box, CircularProgress } from "@mui/material";
import { ExternalReferenceFile } from "../../types/ExternalReferenceFile";

export type DisplayMediaObjectContentProps = {
  mediaObject: MediaObject | ExternalReferenceFile["mediaObjects"][number];
  mediaRotation: number;
};

const DisplayMediaObjectContent = ({ mediaObject, mediaRotation }: DisplayMediaObjectContentProps) => {
  const [displayUrl, setDisplayUrl] = useState<string | null>(mediaObject?.displayUrl || null);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(mediaObject?.downloadUrl || null);

  useEffect(() => {
    if (mediaObject.displayUrl && mediaObject.downloadUrl) {
      setDisplayUrl(mediaObject.displayUrl);
      setDownloadUrl(mediaObject.downloadUrl);
      return;
    }
    setDisplayUrl(null);
    setDownloadUrl(null);
    apiGet("media_objects", mediaObject.id).then((responseMediaObject) => {
      setDisplayUrl(responseMediaObject.displayUrl);
      setDownloadUrl(responseMediaObject.downloadUrl);
    });
  }, [mediaObject]);

  if (!displayUrl || !downloadUrl) {
    return (
      <Box sx={displayMediaObjectStyles.embedContainer}>
        <Box sx={displayMediaObjectStyles.loadingWrapper}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  switch (true) {
    case mediaObject.mimeType === "application/pdf": {
      return (
        <DisplayMediaObjectContentPdf mediaObject={mediaObject} displayUrl={displayUrl} mediaRotation={mediaRotation} />
      );
    }
    case mimeTypes.audio.includes(mediaObject.mimeType!): {
      return (
        <DisplayMediaObjectContentAudio mediaObject={mediaObject} displayUrl={displayUrl} downloadUrl={downloadUrl} />
      );
    }
    case mimeTypes.video.includes(mediaObject.mimeType!): {
      return (
        <DisplayMediaObjectContentVideo
          mediaObject={mediaObject}
          displayUrl={displayUrl}
          mediaRotation={mediaRotation}
          downloadUrl={downloadUrl}
        />
      );
    }

    case mimeTypes.image.includes(mediaObject.mimeType!): {
      return (
        <DisplayMediaObjectContentImage
          mediaObject={mediaObject}
          displayUrl={displayUrl}
          mediaRotation={mediaRotation}
        />
      );
    }

    default: {
      return null;
    }
  }
};

export default DisplayMediaObjectContent;

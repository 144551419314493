import { AbstractCase } from "../../../types/AbstractCase";
import * as stagesCase from "../../Stages/StagesCase";
import moment from "moment/moment";
import { validateFields } from "../statusChangeValidators";
import { dataLeakRequiredFields } from "./dataLeakContractRequiredFields";
import { contractValidators } from "../Contract/contractValidators";

const missingPrimaryCreditAgencyError =
  'Folgende Felder müssen gefüllt sein je nach Datengrundlage. SCHUFA: „Schufa-Auskunft erhalten am“, "Eintragsdatum' +
  ' Schufa ab 25.05.2018" und „Schufa-Auszug Positiveintrag“ + „Datenauskunft negativ geprüft am“ darf nicht gefüllt' +
  " sein. CRIF: „Crif-Auskunft erhalten am“, „Eintragsdatum CRIF ab 25.05.2018“ und „CRIF-Auszug Positiveintrag“ +" +
  " „Datenauskunft negativ geprüft am“ darf nicht gefüllt sein.";
export const dataLeakContractValidators = {
  [stagesCase.STAGE_CREATED]: (dataLeakCase: AbstractCase) =>
    validateFields(dataLeakRequiredFields[stagesCase.STAGE_CREATED](dataLeakCase), dataLeakCase),
  [stagesCase.STAGE_DATA_COMPLETED]: (dataLeakCase: AbstractCase) =>
    validateFields(dataLeakRequiredFields[stagesCase.STAGE_DATA_COMPLETED](dataLeakCase), dataLeakCase),
  [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: (dataLeakCase: AbstractCase) => [], // this stage is not supposed to have any validators, even though it has required fields
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: (dataLeakCase: AbstractCase) => {
    let errorMessages: string[] = [];

    if (!dataLeakCase.primaryCreditAgency) {
      errorMessages.push(missingPrimaryCreditAgencyError);
    }

    return errorMessages;
  },
  [stagesCase.STAGE_CHECKED]: (dataLeakCase: AbstractCase) =>
    validateFields(dataLeakRequiredFields[stagesCase.STAGE_CHECKED](dataLeakCase), dataLeakCase),
  [stagesCase.STAGE_IN_PROGRESS]: (dataLeakCase: AbstractCase) =>
    validateFields(dataLeakRequiredFields[stagesCase.STAGE_IN_PROGRESS](dataLeakCase), dataLeakCase),
  [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: (dataLeakCase: AbstractCase) =>
    validateFields(
      dataLeakRequiredFields[stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT](dataLeakCase),
      dataLeakCase
    ),
  [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: (dataLeakCase: AbstractCase) =>
    validateFields(
      dataLeakRequiredFields[stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED](dataLeakCase),
      dataLeakCase
    ),
  [stagesCase.STAGE_ACTION_REQUIRED]: (dataLeakCase: AbstractCase) =>
    validateFields(dataLeakRequiredFields[stagesCase.STAGE_ACTION_REQUIRED](dataLeakCase), dataLeakCase),
  [stagesCase.STAGE_PREPARE_LAWSUIT]: (dataLeakCase: AbstractCase) => {
    let errorMessages: string[] = [];

    if (!dataLeakCase.primaryCreditAgency) {
      errorMessages.push(missingPrimaryCreditAgencyError);
    }

    return [
      ...errorMessages,
      ...validateFields(dataLeakRequiredFields[stagesCase.STAGE_PREPARE_LAWSUIT](dataLeakCase), dataLeakCase),
    ];
  },
  [stagesCase.STAGE_LAWSUIT]: (dataLeakCase: AbstractCase) => {
    let errorMessages = validateFields(dataLeakRequiredFields[stagesCase.STAGE_LAWSUIT](dataLeakCase), dataLeakCase);

    if (dataLeakCase.insurance.judicialCoverageGranted !== true) {
      errorMessages.push("Die gerichtliche Deckung muss gegeben sein");
    }

    return errorMessages;
  },
  [stagesCase.STAGE_TRIAL]: (dataLeakCase: AbstractCase) => {
    return validateFields(dataLeakRequiredFields[stagesCase.STAGE_TRIAL](dataLeakCase), dataLeakCase);
  },
  [stagesCase.STAGE_EVALUATE_JUDGEMENT]: contractValidators[stagesCase.STAGE_EVALUATE_JUDGEMENT],
  [stagesCase.STAGE_LAWSUIT_COMPLETED]: (dataLeakCase: AbstractCase) => {
    let errorMessages = validateFields(
      dataLeakRequiredFields[stagesCase.STAGE_LAWSUIT_COMPLETED](dataLeakCase),
      dataLeakCase
    );

    if (
      dataLeakCase.lawsuitCompletedReason === "defaultJudgement" &&
      moment().isBefore(moment(dataLeakCase.lawsuitCompletedDate))
    ) {
      errorMessages.push("Das Versäumnisurteil muss rechtskräftig sein (Rechtskräftige VU am)");
    }

    return errorMessages;
  },
  [stagesCase.STAGE_COMPLETED]: (dataLeakCase: AbstractCase) => {
    return validateFields(dataLeakRequiredFields[stagesCase.STAGE_COMPLETED](dataLeakCase), dataLeakCase);
  },
};

import { Box, Stack } from "@mui/material";
import { displayMediaObjectStyles } from "./displayMediaObjectStyle";
import React, { useState } from "react";
import { MediaObject } from "../../types/MediaObject";
import DisplayMediaObjectContentPlaybackErrorMessage from "./DisplayMediaObjectContentPlaybackErrorMessage";
import { ExternalReferenceFile } from "../../types/ExternalReferenceFile";

export type DisplayMediaObjectContentVideoProps = {
  mediaObject: MediaObject | ExternalReferenceFile["mediaObjects"][number];
  displayUrl: string;
  downloadUrl: string;
  mediaRotation: number;
};

export default function DisplayMediaObjectContentVideo({
  mediaObject,
  displayUrl,
  downloadUrl,
  mediaRotation,
}: DisplayMediaObjectContentVideoProps) {
  const [videoError, setVideoError] = useState(false);

  if (videoError) {
    return <DisplayMediaObjectContentPlaybackErrorMessage downloadUrl={downloadUrl} mediaObject={mediaObject} />;
  }

  return (
    <Box sx={displayMediaObjectStyles.embedContainer}>
      <Stack sx={{ ...displayMediaObjectStyles.multimediaContainer, transform: `rotate(${mediaRotation}deg)` }}>
        <video controls onError={() => setVideoError(true)} height={"auto"} width={"800px"} key={mediaObject.id}>
          <source src={displayUrl} type={mediaObject.mimeType!} />
          <DisplayMediaObjectContentPlaybackErrorMessage downloadUrl={downloadUrl} mediaObject={mediaObject} />
        </video>
      </Stack>
    </Box>
  );
}

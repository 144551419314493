import { BackofficeUser } from "./BackofficeUser";

export enum CaseStatus {
  open = "open",
  won = "won",
  lost = "lost",
  inactive = "inactive",
}

export interface BackofficeCase {
  "@id": string;
  id: number;
  lbProductClass: string;
  lbProductId: number;
  label: string;
  customerEmail: string;
  accountManager: BackofficeUser;
  lawyer: BackofficeUser;
  status: CaseStatus;
  lostReason: string | null;
  lostCategory: string | null;
  relatedCases: Array<BackofficeCase>;
  lbLeadStatus: number;
  preventAllSystemActivities: boolean;
  preventAllSystemBeaOrFax: boolean;
  created: string | null;
  updated: string | null;
  acquisitionPartner: string | null;
  activityStatus: number;
}

/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { BookingEntry } from "../../types/BookingEntry";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { SelectProps } from "@mui/material/Select";
import { useInvoiceTypeIdentifiers } from "../../provider/InvoiceTypeIdentifiersProvider";

type RelatedInvoiceNumberSelectProps = {
  bookingEntries: Array<BookingEntry>;
  label?: string;
} & Pick<SelectProps<any>, "value" | "onChange" | "name">;

const RelatedInvoiceNumberSelect = ({
  bookingEntries,
  value,
  onChange,
  name = "relatedInvoiceNumber",
  label = "Bezug auf Rechnung …",
}: RelatedInvoiceNumberSelectProps) => {
  const { isLoading: isLoadingInvoiceTypeIdentifiers, translateInvoiceTypeIdentifier } = useInvoiceTypeIdentifiers();

  const relatedInvoiceBookingEntries = bookingEntries.filter(
    (bookingEntry: BookingEntry) =>
      bookingEntry.invoiceNumber !== null && bookingEntry.debitOrCredit === "debit" && bookingEntry.type === "invoice"
  );

  const getInvoiceName = (bookingEntry: BookingEntry): string | null => {
    const invoiceName =
      bookingEntry.invoiceTypeIdentifier && bookingEntry.productType
        ? translateInvoiceTypeIdentifier(bookingEntry.productType, bookingEntry.invoiceTypeIdentifier)
        : null;

    return invoiceName ? ` - ${invoiceName}` : "";
  };

  if (isLoadingInvoiceTypeIdentifiers) {
    return null;
  }

  return (
    <ValidatorSelect label={label} name={name} value={value} onChange={onChange}>
      {relatedInvoiceBookingEntries.map((bookingEntry: BookingEntry) => {
        return (
          bookingEntry.invoiceNumber && (
            <MenuItem key={bookingEntry.invoiceNumber} value={bookingEntry.invoiceNumber}>
              {bookingEntry.invoiceNumber}
              {getInvoiceName(bookingEntry)}
            </MenuItem>
          )
        );
      })}
    </ValidatorSelect>
  );
};

export default RelatedInvoiceNumberSelect;

/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

export const navigationBarNotificationItemStyle = {
  contentBox: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    padding: "0.5rem",
    borderColor: "grey.300",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: "4px",
    wordBreak: "break-word",
    whiteSpace: "normal",
    "& p": { margin: 0 },
    "&:hover": {
      backgroundColor: "grey.300",
    },
  },
  notificationBubble: {
    color: "primary.main",
    fontSize: "2rem",
    lineHeight: "1rem",
  },
};

export default navigationBarNotificationItemStyle;

import { ReferenceFile } from "./ReferenceFile";
import { MediaObject } from "./MediaObject";

export type ExternalReferenceFile = ReferenceFile & {
  mediaObjects: Array<
    Pick<
      MediaObject,
      "id" | "displayUrl" | "downloadUrl" | "description" | "originalName" | "mimeType" | "created" | "size"
    > & {
      selectionDate: string;
    }
  >;
  productData: {
    productClassName: string;
    productCategory: string;
    client: {
      gender: string;
      givenName: string;
      familyName: string;
    };
  };
};

export function isExternalReferenceFileMediaObject(
  mediaObject: ExternalReferenceFile["selectedMediaObjects"][number] | MediaObject
): mediaObject is ExternalReferenceFile["selectedMediaObjects"][number] {
  return "selectionDate" in mediaObject;
}

import { AbstractCase } from "../../../types/AbstractCase";
import * as stagesCase from "../../Stages/StagesCase";
import moment from "moment/moment";
import { validateFields } from "../statusChangeValidators";
import { contractRequiredFields } from "./contractRequiredFields";
import _ from "lodash";

export const contractValidators = {
  [stagesCase.STAGE_CREATED]: (contractCase: AbstractCase) =>
    validateFields(contractRequiredFields[stagesCase.STAGE_CREATED](), contractCase),
  [stagesCase.STAGE_DATA_COMPLETED]: (contractCase: AbstractCase) =>
    validateFields(contractRequiredFields[stagesCase.STAGE_DATA_COMPLETED](), contractCase),
  [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: () => [], // this stage is not supposed to have any validators, even though it has required fields
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: (contractCase: AbstractCase) =>
    validateFields(contractRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](contractCase), contractCase),
  [stagesCase.STAGE_CHECKED]: (contractCase: AbstractCase) =>
    validateFields(contractRequiredFields[stagesCase.STAGE_CHECKED](contractCase), contractCase),
  [stagesCase.STAGE_IN_PROGRESS]: (contractCase: AbstractCase) =>
    validateFields(contractRequiredFields[stagesCase.STAGE_IN_PROGRESS](), contractCase),
  [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: (contractCase: AbstractCase) =>
    validateFields(contractRequiredFields[stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT](), contractCase),
  [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: (contractCase: AbstractCase) =>
    validateFields(
      contractRequiredFields[stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED](contractCase),
      contractCase
    ),
  [stagesCase.STAGE_ACTION_REQUIRED]: (contractCase: AbstractCase) =>
    validateFields(contractRequiredFields[stagesCase.STAGE_ACTION_REQUIRED](), contractCase),
  [stagesCase.STAGE_PREPARE_LAWSUIT]: (contractCase: AbstractCase) => {
    return validateFields(contractRequiredFields[stagesCase.STAGE_PREPARE_LAWSUIT](), contractCase);
  },
  [stagesCase.STAGE_LAWSUIT]: (contractCase: AbstractCase) => {
    let errorMessages = validateFields(contractRequiredFields[stagesCase.STAGE_LAWSUIT](contractCase), contractCase);

    if (contractCase.insurance.judicialCoverageGranted !== true) {
      errorMessages.push("Die gerichtliche Deckung muss gegeben sein");
    }

    return errorMessages;
  },
  [stagesCase.STAGE_TRIAL]: (contractCase: AbstractCase) => {
    return validateFields(contractRequiredFields[stagesCase.STAGE_TRIAL](), contractCase);
  },
  [stagesCase.STAGE_EVALUATE_JUDGEMENT]: () => true,
  [stagesCase.STAGE_LAWSUIT_COMPLETED]: (contractCase: AbstractCase) => {
    let errorMessages = validateFields(
      contractRequiredFields[stagesCase.STAGE_LAWSUIT_COMPLETED](contractCase),
      contractCase
    );

    if (
      contractCase.lawsuitCompletedReason === "defaultJudgement" &&
      moment().isBefore(moment(contractCase.lawsuitCompletedDate))
    ) {
      errorMessages.push("Das Versäumnisurteil muss rechtskräftig sein (Rechtskräftige VU am)");
    }

    return errorMessages;
  },
  [stagesCase.STAGE_COMPLETED]: (contractCase: AbstractCase) => {
    let errorMessages = validateFields(contractRequiredFields[stagesCase.STAGE_COMPLETED](contractCase), contractCase);

    if (
      contractCase.hasMonitoringBilling === null &&
      _.includes([2], contractCase.insurance.insurance.id) &&
      _.includes(
        [stagesCase.STAGE_ACTION_REQUIRED, stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED],
        contractCase.leadStatus
      ) &&
      contractCase.caseCategory !== "advisory"
    ) {
      errorMessages.push("Bitte entscheiden und auswählen, ob Monitoringgebühr berechnet werden muss.");
    }

    return errorMessages;
  },
};

import React from "react";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { apiPut } from "../../services/Api/apiCall";
import moment from "moment/moment";
import { WireTransfer } from "../../types/WireTransfer";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { CheckboxElement, FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import SubmitButton from "../Button/SubmitButton";
import _ from "lodash";
import { requiredFieldDefault } from "../../services/reactHookFormValidationRules";
import { Box } from "@mui/material";

type DeleteWireTransferProps = {
  handleClose: Function;
  refreshCollection: Function;
  open: boolean;
  wireTransfer: WireTransfer;
};

export default function DeleteWireTransfer({
  wireTransfer,
  handleClose,
  open,
  refreshCollection,
}: DeleteWireTransferProps) {
  const currentUser = useCurrentUser();

  const defaultValues = {
    notifyCreator: true,
    deletionReason: "",
  };

  const handleDelete = (values: typeof defaultValues) => {
    apiPut("wiretransfers", wireTransfer.id, {
      deletedDate: moment(),
      deletedBy: currentUser["@id"],
      deletionReason: values.deletionReason,
    }).then(() => {
      refreshCollection();
    });
  };

  const formContext = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  const DELETION_REASON_MAX_LENGTH = 600;
  const deletionReason = formContext.watch("deletionReason", "");

  return (
    <LegalbirdIoModal handleClose={handleClose} open={open} title={"Überweisung löschen?"}>
      <FormContainer formContext={formContext} onSuccess={handleDelete}>
        <CheckboxElement
          name={"notifyCreator"}
          label={"Ersteller über Löschung informieren"}
          onChange={() => formContext.clearErrors("deletionReason")}
        />
        {formContext.watch("notifyCreator") && (
          <TextFieldElement
            placeholder={"Grund für Löschung der Überweisung"}
            multiline
            rows={7}
            name={"deletionReason"}
            helperText={
              <Box>
                {deletionReason.length} / {DELETION_REASON_MAX_LENGTH}
              </Box>
            }
            rules={{
              ...requiredFieldDefault,
              maxLength: DELETION_REASON_MAX_LENGTH,
            }}
            inputProps={{ maxLength: DELETION_REASON_MAX_LENGTH }}
          />
        )}
        <SubmitButton
          isLoading={formContext.formState.isSubmitting}
          variant={"contained"}
          type={"submit"}
          error={_.keys(formContext.formState.errors).length > 0}
        >
          Löschen
        </SubmitButton>
      </FormContainer>
    </LegalbirdIoModal>
  );
}

import * as status from "../LeadStatus/StatusCase";
import { StageMetaData } from "../../types/ProductService/ProductService";
import { AbstractCase } from "../../types/AbstractCase";

const STAGE_CREATED = 10;
const STAGE_DATA_COMPLETED = 20;
const STAGE_WAITING_FOR_FEEDBACK = 25;
const STAGE_DATA_COMPLETED_LAWFIRM = 30;
const STAGE_CHECKED = 50;
const STAGE_IN_PROGRESS = 100;
const STAGE_COMPLETED = 200;

// additional in progress status

const STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT = 110;
const STAGE_EXTRAJUDICIAL_REACTION_RECEIVED = 120;
const STAGE_ACTION_REQUIRED = 125;
const STAGE_PREPARE_LAWSUIT = 127;
const STAGE_LAWSUIT = 130;
const STAGE_TRIAL = 140;

const STAGE_EVALUATE_JUDGEMENT = 160;
const STAGE_LAWSUIT_COMPLETED = 180;

export {
  STAGE_CREATED,
  STAGE_DATA_COMPLETED,
  STAGE_WAITING_FOR_FEEDBACK,
  STAGE_DATA_COMPLETED_LAWFIRM,
  STAGE_CHECKED,
  STAGE_IN_PROGRESS,
  STAGE_COMPLETED,
  STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
  STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
  STAGE_ACTION_REQUIRED,
  STAGE_PREPARE_LAWSUIT,
  STAGE_LAWSUIT,
  STAGE_TRIAL,
  STAGE_EVALUATE_JUDGEMENT,
  STAGE_LAWSUIT_COMPLETED,
};

export const stageMetaDataCase: StageMetaData = {
  [STAGE_CREATED]: {
    label: "Erstellt",
    stage: STAGE_CREATED,
    leadStatus: status.STATUS_CREATED,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: false,
  },
  [STAGE_DATA_COMPLETED]: {
    label: "Kundendaten vollständig",
    stage: STAGE_DATA_COMPLETED,
    leadStatus: status.STATUS_DATA_COMPLETED,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) => product.stage >= STAGE_DATA_COMPLETED_LAWFIRM,
    showInBoard: true,
  },
  [STAGE_WAITING_FOR_FEEDBACK]: {
    label: "Weitere Klärung notwendig",
    stage: STAGE_WAITING_FOR_FEEDBACK,
    leadStatus: status.STATUS_WAITING_FOR_FEEDBACK,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) => product.stage >= STAGE_DATA_COMPLETED,
    showInBoard: true,
  },
  [STAGE_DATA_COMPLETED_LAWFIRM]: {
    label: "Daten Anwalt vollständig",
    stage: STAGE_DATA_COMPLETED_LAWFIRM,
    leadStatus: status.STATUS_DATA_COMPLETED_LAWFIRM,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      !product.advisoryHandling && product.stage !== STAGE_DATA_COMPLETED_LAWFIRM,
    showInBoard: true,
  },
  [STAGE_CHECKED]: {
    label: "Überprüft",
    stage: STAGE_CHECKED,
    leadStatus: status.STATUS_CHECKED,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) => product.stage !== STAGE_CREATED,
    showInBoard: true,
  },
  [STAGE_IN_PROGRESS]: {
    label: "In Bearbeitung",
    stage: STAGE_IN_PROGRESS,
    leadStatus: status.STATUS_IN_PROGRESS,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.advisoryHandling && product.stage !== STAGE_IN_PROGRESS && product.stage >= STAGE_CHECKED,
    showInBoard: true,
  },
  [STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: {
    label: "Schreiben an Gegenseite",
    stage: STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
    leadStatus: status.STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      !product.advisoryHandling &&
      product.stage !== STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT &&
      product.stage >= STAGE_CHECKED,
    showInBoard: true,
  },
  [STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: {
    label: "Reaktion der Gegenseite",
    stage: STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
    leadStatus: status.STATUS_EXTRAJUDICIAL_REACTION_RECEIVED,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      !product.advisoryHandling &&
      product.stage !== STAGE_EXTRAJUDICIAL_REACTION_RECEIVED &&
      product.stage >= STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
    showInBoard: true,
  },
  [STAGE_ACTION_REQUIRED]: {
    label: "Weiteres Vorgehen notwendig",
    stage: STAGE_ACTION_REQUIRED,
    leadStatus: status.STATUS_ACTION_REQUIRED,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      !product.advisoryHandling &&
      product.stage !== STAGE_ACTION_REQUIRED &&
      product.stage >= STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
    showInBoard: true,
  },
  [STAGE_PREPARE_LAWSUIT]: {
    label: "Vorbereitung Klage",
    stage: STAGE_PREPARE_LAWSUIT,
    leadStatus: status.STATUS_PREPARE_LAWSUIT,
    isActive: ({ product }: { product: AbstractCase }) => product.hasMonitoringBilling !== true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      !product.advisoryHandling && product.stage !== STAGE_PREPARE_LAWSUIT && product.stage >= STAGE_ACTION_REQUIRED,
    showInBoard: true,
  },
  [STAGE_LAWSUIT]: {
    label: "Gerichtliches Verfahren",
    stage: STAGE_LAWSUIT,
    leadStatus: status.STATUS_LAWSUIT,
    isActive: ({ product }: { product: AbstractCase }) =>
      product.finalClaimStatusClient !== "fullyAgreed" && product.hasMonitoringBilling !== true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      !product.advisoryHandling &&
      product.stage !== STAGE_LAWSUIT &&
      product.stage >= STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
    showInBoard: true,
  },
  [STAGE_TRIAL]: {
    label: "Gerichtstermin",
    stage: STAGE_TRIAL,
    leadStatus: status.STATUS_TRIAL,
    isActive: ({ product }: { product: AbstractCase }) =>
      product.finalClaimStatusClient !== "fullyAgreed" && product.hasMonitoringBilling !== true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      !product.advisoryHandling && product.stage !== STAGE_TRIAL && product.stage >= STAGE_LAWSUIT,
    showInBoard: true,
  },
  [STAGE_EVALUATE_JUDGEMENT]: {
    label: "Urteilsprüfung",
    stage: STAGE_EVALUATE_JUDGEMENT,
    leadStatus: status.STATUS_EVALUATE_JUDGEMENT,
    isActive: ({ product }: { product: AbstractCase }) =>
      product.hasMonitoringBilling !== true &&
      product.judgementReceivedDate &&
      product.costBearingByOpponentRelative !== null &&
      product.costBearingByOpponentRelative < 100,
    isClickable: ({ product }: { product: AbstractCase }) =>
      stageMetaDataCase[STAGE_EVALUATE_JUDGEMENT].isActive({ product }) && product.stage > STAGE_LAWSUIT,
    showInBoard: true,
  },
  [STAGE_LAWSUIT_COMPLETED]: {
    label: "Rechtsstreit beendet",
    stage: STAGE_LAWSUIT_COMPLETED,
    leadStatus: status.STATUS_LAWSUIT_COMPLETED,
    isActive: ({ product }: { product: AbstractCase }) => product.finalClaimStatusClient !== "fullyAgreed",
    isClickable: ({ product }: { product: AbstractCase }) =>
      [STAGE_LAWSUIT, STAGE_TRIAL, STAGE_EVALUATE_JUDGEMENT].includes(product.stage),
    showInBoard: true,
  },
  [STAGE_COMPLETED]: {
    label: "Abgeschlossen",
    stage: STAGE_COMPLETED,
    leadStatus: status.STATUS_COMPLETED,
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.stage !== STAGE_COMPLETED &&
      product.stage !== STAGE_LAWSUIT &&
      product.stage !== STAGE_TRIAL &&
      product.caseCategoryAssessment !== "reject",
    showInBoard: true,
  },
};

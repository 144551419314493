import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Badge from "../Badges/Badge";
import classNames from "classnames";
import { ACTIVITY_FUTURE, ACTIVITY_OVERDUE, ACTIVITY_TODAY, NO_OPEN_ACTIVITY } from "../../services/Stages/Stages";
import Avatar from "@mui/material/Avatar";
import _ from "lodash";
import { getCaseLink, isContractProduct, isDocumentProduct } from "../../services/Product/ProductService";
import { Link } from "react-router-dom";
import { getActivityInfo, getBadgeContent, getLabelFromHit } from "../../services/Board/algolia";
import { translate } from "../../services/Translations/translatorService";
import { BackofficeUser } from "../../types/BackofficeUser";
import { CaseHit } from "../Case/CaseEntityPicker/Hit/hitType";
import { Theme, Tooltip } from "@mui/material";
import { ArrowUpwardOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: "#a1ccca",
    marginRight: "0.2rem",
    width: "2rem",
    height: "2rem",
    position: "static",
  },
  boardCardContainer: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  card: {
    textAlign: "left",
    minWidth: 150,
    maxWidth: 600,
    marginTop: "1rem",
    padding: "0.5rem",
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    wordWrap: "break-word",
    marginBottom: 10,
  },
  caseCategory: {
    marginTop: 10,
  },
  activity: {
    textAlign: "right",
  },
  circle: {
    height: 20,
    width: 20,
    borderRadius: "50%",
    display: "inline-block",
  },
  [NO_OPEN_ACTIVITY]: {
    backgroundColor: "#ffed00",
  },
  [ACTIVITY_TODAY]: {
    backgroundColor: "#009640",
  },
  [ACTIVITY_FUTURE]: {
    backgroundColor: "#cdcdcd",
  },
  [ACTIVITY_OVERDUE]: {
    backgroundColor: "#e30613",
  },
  won: {
    border: "2px solid #009640",
  },
  lost: {
    border: "2px solid #e30613",
  },
  inactive: {
    border: "2px solid #ff9600",
  },
}));
type BoardCardProps = {
  hit: CaseHit;
  backofficeUsers: BackofficeUser[];
};
const BoardCard = ({ hit, backofficeUsers }: BoardCardProps) => {
  const classes = useStyles({});
  const borderClass = classes[hit.caseType];

  const selectedAccountManager = _.find(backofficeUsers, (user) => {
    return user.id === hit.accountManager;
  });
  const selectedLawyer = _.find(backofficeUsers, (user) => {
    return user.id === hit.responsibleLawyer;
  });

  const activityInfo = getActivityInfo(hit);

  return (
    <Paper className={classNames(classes.card, borderClass)}>
      <Grid
        component={Link}
        to={getCaseLink({ productClassName: hit.product, id: hit.productId })}
        className={classes.boardCardContainer}
        container
      >
        <Grid item xs={10} className={classes.name}>
          {getLabelFromHit(hit)}
        </Grid>
        <Grid item xs={2} className={classes.activity}>
          {!(isDocumentProduct(hit.product) && activityInfo === NO_OPEN_ACTIVITY) && (
            <div className={classNames(classes.circle, classes[activityInfo])} />
          )}
          {hit.isHigherInstanceCase && (
            <Tooltip title="Höhere Instanz">
              <ArrowUpwardOutlined />
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={5}>
          <Badge text={getBadgeContent(hit)} size={"small"} />
        </Grid>
        <Grid item xs={7}>
          <Grid container wrap={"nowrap"} justifyContent={"flex-end"}>
            <Grid item>
              {selectedAccountManager && (
                <Avatar src={selectedAccountManager.avatar || undefined} className={classes.avatar}>
                  {selectedAccountManager.person.givenName.charAt(0) +
                    selectedAccountManager.person.familyName.charAt(0)}
                </Avatar>
              )}
            </Grid>
            <Grid item>
              {selectedLawyer && (
                <Avatar src={selectedLawyer.avatar || undefined} className={classes.avatar}>
                  {selectedLawyer.person.givenName.charAt(0) + selectedLawyer.person.familyName.charAt(0)}
                </Avatar>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isContractProduct(hit.product) && (
          <Grid item xs={12} className={classes.caseCategory}>
            <Badge
              text={translate(hit.product + ".caseCategory.values." + hit.caseCategory, "Noch nicht gesetzt")}
              size={"small"}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default BoardCard;

/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { AbstractCase } from "../../../../types/AbstractCase";
import * as stagesCase from "../../../Stages/StagesCase";
import { dateFieldDefault, percentageFieldDefault } from "../../../validationRules";
import _ from "lodash";
import { getCommonContractLawCaseRejectionCategorySelectOptions } from "../contract/contractLawFormHelperService";
import { objectivesLawFirm } from "../modules/objectiveLawfirmValues";
import { arrayToSelectOptions } from "../../../formServiceFunctions";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import { UseForm } from "../../../../types/UseForm";
import { stageFormDefinition as contractStageFormDefinition } from "../contract/stageFormDefinition";
import DateChangeButtonsToolbar from "../../../../components/Validator/DateChangeButtonsToolbar";
import { FormDefinition } from "../../../../types/ProductService/ProductService";
import { FormElementPropsWithoutProduct } from "../../../../components/Case/CaseForm/FormElement";
import { BackofficeUser } from "../../../../types/BackofficeUser";

export function stageFormDefinition(product: AbstractCase, currentUser?: BackofficeUser): ReturnType<FormDefinition> {
  const dataCompletedFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategory`,
      isDisabled: ({ product }: { product: AbstractCase }) => product.caseCategory === "advisory",
      options: ({ product }: { product: AbstractCase }) => {
        return product.caseCategory === "advisory"
          ? [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }]
          : [{ value: "enforceClaim" }, { value: "defendClaim" }];
      },

      alert: {
        onChange:
          "Das Ändern der Position des Mandanten (Kläger/Beklagter) führt dazu, dass sich viele " +
          "Felder ändern und auch Felder verschwinden. Die neuen Felder müssen dann von Ihnen neu " +
          "befüllt werden, damit der Fall weiter bearbeitet werden kann. Möchten Sie die Position " +
          "des Mandanten im Verfahren tatsächlich ändern?",
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.objectiveClient`,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
      options: () => [
        { value: "compensation" },
        { value: "reduction" },
        { value: "replacement" },
        { value: "repair" },
        { value: "withdrawal" },
        { value: "fulfillment" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.defenseProcessStatus`,
      isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_DATA_COMPLETED_LAWFIRM,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
      options: () => [
        { value: "reminder" },
        { value: "debtCollection" },
        { value: "paymentOrder" },
        { value: "enforcementOrder" },
        { value: "lawsuit" },
        { value: "none" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.paymentOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.enforcementOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.advisoryHandling === true || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.defectsDescriptionClient`,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.advisoryHandling === true ||
        !_.has(product, "defectsDescriptionLawFirm") ||
        product.caseCategory !== "enforceClaim" ||
        product.isLawsuitHandling ||
        (!product.hasDefects && product.reasonForDispute !== "hasDefects"),
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }: { product: AbstractCase }) => product.stage! > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory === "advisory",
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
    {
      type: "CaseRejectionCategorySelect",
      path: `${product.productClassName}.caseRejectionCategory`,
      options: getCommonContractLawCaseRejectionCategorySelectOptions,
      isDisabled: ({ product }: { product: AbstractCase }) => product.stage! > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
        product.caseCategory === "advisory" || values[product.productClassName].caseCategoryAssessment !== "reject",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.assessmentRejectionText`,
      isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
        values[product.productClassName].caseCategoryAssessment !== "reject" || product.caseCategory === "advisory",
      additionalProps: {
        multiline: true,
        rows: 12,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.objectiveLawFirm`,
      isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
        values[product.productClassName].caseCategoryAssessment === "reject" || product.isLawsuitHandling,
      options: ({ product }: { product: AbstractCase }) => {
        if (_.isEmpty(product.possibleObjectives)) {
          return objectivesLawFirm;
        } else {
          return arrayToSelectOptions(product.possibleObjectives);
        }
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.objectiveLawFirmSecondary`,
      isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
        values[product.productClassName].caseCategoryAssessment === "reject" || product.isLawsuitHandling,
      options: ({ product }: { product: AbstractCase }) => {
        if (_.isEmpty(product.possibleObjectives)) {
          return objectivesLawFirm;
        } else {
          return arrayToSelectOptions(product.possibleObjectives);
        }
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.objectiveLawFirmTertiary`,
      isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
        values[product.productClassName].caseCategoryAssessment === "reject" || product.isLawsuitHandling,
      options: ({ product }: { product: AbstractCase }) => {
        if (_.isEmpty(product.possibleObjectives)) {
          return objectivesLawFirm;
        } else {
          return arrayToSelectOptions(product.possibleObjectives);
        }
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.advisoryHandling === true || product.isLawsuitHandling,
      additionalProps: {
        toolbar: DateChangeButtonsToolbar,
      },
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.claimedAmountLawFirm`,
      isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.advisoryHandling === true || product.isLawsuitHandling,
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.defectsDescriptionLawFirm`,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.advisoryHandling === true ||
        !_.has(product, "defectsDescriptionLawFirm") ||
        product.caseCategory !== "enforceClaim" ||
        product.isLawsuitHandling ||
        (!product.hasDefects && product.reasonForDispute !== "hasDefects"),
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtPostalCode`,
      helperText:
        "Bitte hier die Postleitzahl eintragen, die für den Gerichtsstand ermittelt wurde und NICHT die Postleitzahl des Gerichtes.",
      isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
        values[product.productClassName].caseCategoryAssessment === "reject" ||
        !product.isLawsuitHandling ||
        product.caseCategory === "defendClaim" ||
        product.isHigherInstanceCase,
      alert: {
        onChange:
          "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
      },
    },
  ];

  const lawsuitFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimSentDate`,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.paymentOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.statementOfClaimFiledDate || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.enforcementOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.statementOfClaimFiledDate || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.advisoryHandling === true || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtReference`,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfDefenseFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.replicaSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfDefenseSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.replicaFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.caseCategory !== "defendClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.judgementReceivedDate`,
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.lawsuitCompletedReason`,
      options: () => [
        { value: "courtDecision" },
        { value: "settlement" },
        { value: "lawsuitRevocation" },
        { value: "resolution" },
        { value: "defaultJudgement" },
        { value: "judgementAppeal" },
      ],
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.customerLawsuitCompletedApprovalDate`,
      translationPath: ({ values }) =>
        `${product.productClassName}.customerLawsuitCompletedApprovalDate.${
          values[product.productClassName].lawsuitCompletedReason
        }`,
      validators: dateFieldDefault,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        _.includes(
          ["courtDecision", "defaultJudgement", "judgementAppeal"],
          values[product.productClassName].lawsuitCompletedReason
        ) || !values[product.productClassName].lawsuitCompletedReason,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.lawsuitCompletedDate`,
      isHidden: ({ values }: { values: UseForm["values"] }) => !values[product.productClassName].lawsuitCompletedReason,
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.hasStandardInvoicing`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.hasStandardInvoicing`),
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.netRvgAmount`,
      isHidden: ({ values, product }: { values: UseForm["values"]; product: AbstractCase }) =>
        values[product.productClassName].hasStandardInvoicing,
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDisputePreliminary`,
      fieldType: "money",
      numberType: "float",
      isDisabled: () => true,
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDispute`,
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.costBearingByOpponentRelative`,
      validators: percentageFieldDefault,
      isDisabled: ({ product }: { product: AbstractCase }) =>
        !_.includes(
          [stagesCase.STAGE_LAWSUIT_COMPLETED, stagesCase.STAGE_TRIAL, stagesCase.STAGE_LAWSUIT],
          product.stage
        ),
      fieldType: "percentage",
      numberField: "float",
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.principalClaim`,
      isHidden: ({ values, product }: { values: UseForm["values"]; product: AbstractCase }) =>
        values[product.productClassName].caseCategory !== "enforceClaim" ||
        values[product.productClassName].lawsuitCompletedReason === "lawsuitRevocation" ||
        values[product.productClassName].costBearingByOpponentRelative === "0",
      numberType: "float",
      fieldType: "money",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.principalClaimDefaultDate`,
      isHidden: ({ values, product }: { values: UseForm["values"]; product: AbstractCase }) =>
        values[product.productClassName].caseCategory !== "enforceClaim" ||
        values[product.productClassName].lawsuitCompletedReason === "lawsuitRevocation" ||
        values[product.productClassName].costBearingByOpponentRelative === "0",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.accessoryClaim`,
      isHidden: ({ values, product }: { values: UseForm["values"]; product: AbstractCase }) =>
        values[product.productClassName].caseCategory !== "enforceClaim" ||
        values[product.productClassName].lawsuitCompletedReason === "lawsuitRevocation" ||
        values[product.productClassName].costBearingByOpponentRelative === "0",
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.accessoryClaimDefaultDate`,
      isHidden: ({ values, product }: { values: UseForm["values"]; product: AbstractCase }) =>
        values[product.productClassName].caseCategory !== "enforceClaim" ||
        values[product.productClassName].lawsuitCompletedReason === "lawsuitRevocation" ||
        values[product.productClassName].costBearingByOpponentRelative === "0",
      validators: dateFieldDefault,
    },
  ];

  return {
    [stagesCase.STAGE_CREATED]: contractStageFormDefinition(product, currentUser)[stagesCase.STAGE_CREATED],
    [stagesCase.STAGE_DATA_COMPLETED]: dataCompletedFields,
    [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: dataCompletedFields,
    [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: contractStageFormDefinition(product, currentUser)[
      stagesCase.STAGE_DATA_COMPLETED
    ],
    [stagesCase.STAGE_CHECKED]: contractStageFormDefinition(product, currentUser)[stagesCase.STAGE_CHECKED],
    [stagesCase.STAGE_IN_PROGRESS]: contractStageFormDefinition(product, currentUser)[stagesCase.STAGE_IN_PROGRESS],
    [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: contractStageFormDefinition(product, currentUser)[
      stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT
    ],
    [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: contractStageFormDefinition(product, currentUser)[
      stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED
    ],
    [stagesCase.STAGE_ACTION_REQUIRED]: contractStageFormDefinition(product, currentUser)[
      stagesCase.STAGE_ACTION_REQUIRED
    ],
    [stagesCase.STAGE_PREPARE_LAWSUIT]: contractStageFormDefinition(product, currentUser)[
      stagesCase.STAGE_PREPARE_LAWSUIT
    ],
    [stagesCase.STAGE_LAWSUIT]: lawsuitFields,
    [stagesCase.STAGE_TRIAL]: lawsuitFields,
    [stagesCase.STAGE_EVALUATE_JUDGEMENT]: contractStageFormDefinition(product, currentUser)[
      stagesCase.STAGE_EVALUATE_JUDGEMENT
    ],
    [stagesCase.STAGE_LAWSUIT_COMPLETED]: contractStageFormDefinition(product, currentUser)[
      stagesCase.STAGE_LAWSUIT_COMPLETED
    ],
    [stagesCase.STAGE_COMPLETED]: contractStageFormDefinition(product, currentUser)[stagesCase.STAGE_COMPLETED],
  };
}

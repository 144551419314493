import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ApiClient from "../../../services/ApiClient";
import ButtonLoading from "../../Button/ButtonLoading";
import moment from "moment";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../services/ReactQuery/reactQueryService";
import MediaObjectLabelAndActionForm from "../../MediaObject/MediaObjectLabelAndActionForm";
import { FormContainer, SubmitHandler, useForm } from "react-hook-form-mui";

const MediaObjectFormModal = ({
  open,
  closeDialog,
  product,
  fileData,
  submitDisabled,
  children = null,
  requestUri = "media_objects",
  contentType = "multipart/form-data",
  errorMessage = "Datei konnte nicht hinzugefügt werden",
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const initialValues = {
    description: "Dokument vom " + moment().format("DD.MM.YYYY"),
  } as Record<string, any>;

  const formContext = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const showExtraFields = formContext.watch("actionIdentifier")?.value === "NO_ACTION";

  useEffect(() => {
    if (!open) {
      resetDialog();
      return;
    }
  }, [open]);

  const getRequestData = (values: Record<string, any>) => {
    let payload: Record<string, any> = {};

    _.forEach(fileData, (value, key) => {
      payload[key] = value;
    });

    payload["product"] = product.productClassName;
    payload["productId"] = product.id;
    payload["documentClassLabelAi"] = values.documentClassLabelAi!.value;
    payload["actionIdentifier"] =
      typeof values.actionIdentifier !== "undefined" ? values.actionIdentifier.value : "NO_ACTION";

    if (showExtraFields) {
      payload["showInGallery"] = values.showInGallery;
      payload["showInExternalView"] = values.showInGallery;
      payload["description"] = values.description;
      payload["electronicFileFolderPath"] = values.electronicFileFolderPath;
    }

    if (contentType === "multipart/form-data") {
      let formData = new FormData();
      _.forEach(payload, (value, key) => {
        formData.append(key, value);
      });
      return formData;
    }
    return JSON.stringify(payload);
  };

  const onSubmit: SubmitHandler<typeof initialValues> = async (values) => {
    setIsLoading(true);
    let headers = new Headers();
    headers.set("Content-Type", contentType);
    try {
      await ApiClient.post(requestUri, {
        headers: headers,
        body: getRequestData(values),
      });
    } catch (e) {
      enqueueSnackbar(errorMessage, {
        variant: "custom",
        isNonInteractive: true,
        persist: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));
    setIsLoading(false);
    resetDialog();
  };

  const resetDialog = () => {
    formContext.reset();
    closeDialog();
  };

  return (
    <LegalbirdIoModal
      handleClose={resetDialog}
      open={open}
      disableBackdropClick
      title={"Datei hochladen"}
      submitButton={
        <FormContainer formContext={formContext} onSuccess={onSubmit}>
          <ButtonLoading variant={"contained"} type={"submit"} disabled={submitDisabled} isLoading={isLoading}>
            Datei hinzufügen
          </ButtonLoading>
        </FormContainer>
      }
    >
      <FormContainer formContext={formContext} onSuccess={onSubmit}>
        {children}
        <Grid container spacing={2}>
          <MediaObjectLabelAndActionForm
            productClassName={product.productClassName}
            showExtraFields={showExtraFields}
          />
        </Grid>
      </FormContainer>
    </LegalbirdIoModal>
  );
};

export default MediaObjectFormModal;

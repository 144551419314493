import React, { useEffect, useState } from "react";
import { EditorState, Modifier } from "draft-js";
import { Box } from "@mui/material";
import _ from "lodash";
import ApiClient from "../../services/ApiClient";
import { fillTemplate } from "../../services/Template/templateService";
import { htmlToBlockMap } from "../../services/Editor/editorFunctions";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";
import { AbstractCase } from "../../types/AbstractCase";
import { HydraResult } from "../../types/HydraResult";
import Template from "../../types/Template";
import { AutoCompleteOptionWithValue } from "../../types/AutoCompleteOptionWithValue";
import { FieldValues } from "react-hook-form";

type AddTextBlockProps = {
  editorState?: EditorState; //is mandatory but passed automatically by the editor but i dont know how to tell that to typescript
  onChange?: Function; //is mandatory but passed automatically by the editor but i dont know how to tell that to typescript
  product: AbstractCase;
  formValues: FieldValues;
};

const AddTextBlock = ({ editorState, onChange, product, formValues }: AddTextBlockProps) => {
  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    ApiClient.get("templates").then((result: HydraResult<Template>) => {
      const textTemplates = _.filter(result["hydra:member"], (template) => {
        return (
          template.type === "text" &&
          _.includes(
            [
              "general",
              product.productCategory === "contractLaw" && product.productClassName !== "dataLeakContract"
                ? "contractLaw"
                : product.productClassName,
            ],
            template.productClassName
          )
        );
      });
      setTemplates(textTemplates);
    });
  }, []);

  //can be removed if you find out how to tell typescript that its automatically passed by the editor
  if (!editorState || !onChange) {
    return null;
  }

  const addTextBlock = (templateContent: Template["content"]) => {
    if (!templateContent) {
      return;
    }
    const contentToInsert = htmlToBlockMap(fillTemplate(templateContent, product, formValues));
    const contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      contentToInsert,
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const handlePlaceholderClick = (template: Template) => {
    addTextBlock(`${template.content}`);
  };
  return (
    <Box width={"150px"}>
      <LegalbirdAutoComplete<AutoCompleteOptionWithValue>
        value={null}
        setValue={(event, option) => {
          const template = templates.find((t) => t.id === option?.value);
          if (template) {
            handlePlaceholderClick(template);
          }
        }}
        textFieldProps={{ variant: "standard", fullWidth: true, placeholder: "Text einfügen" }}
        textFieldStyles={{ margin: 0 }}
        options={[
          ...templates.map((template) => ({
            value: template.id,
            label: template.label,
          })),
        ]}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        label={""}
      />
    </Box>
  );
};

export default AddTextBlock;

/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { userHasOneOfTheseRoles } from "./backofficeUserService";
import { BackofficeUser, Roles } from "../types/BackofficeUser";

type RightConfigType = {
  roles?: Array<Roles>;
  allowedUsers?: Array<number>;
};

export enum Rights {
  invoiceToCreditNote = "INVOICE_TO_CREDIT_NOTE",
}

// should be retrieved from backend as user roles are
const rightsConfig: Record<Rights, RightConfigType> = {
  [Rights.invoiceToCreditNote]: {
    allowedUsers: [
      17, // Anna
      6, // Frank
      33, // Michael
      35, // Lara
      40, // Kristina
    ],
  },
};

// TODO: add logic for disallowed users to be able to set a user base by roles and remove specific users even if they have the role
export function userHasRight(backofficeUser: BackofficeUser, right: Rights) {
  // if user belongs to an allowed role return true
  if (userHasOneOfTheseRoles(backofficeUser, rightsConfig[right].roles ?? [])) {
    return true;
  }

  // check if user is allowed user if no roles matches
  return !!rightsConfig[right].allowedUsers?.includes(backofficeUser.id);
}

import React, { createRef, useRef, useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import { Box, Button, Stack } from "@mui/material";
import ReferenceFileMediaObject from "../MediaObject/ReferenceFileMediaObject";
import DisplayMediaObjects from "../MediaObject/DisplayMediaObjects";
import { ExternalReferenceFile as ExternalReferenceFileType } from "../../types/ExternalReferenceFile";
import { MediaObject } from "../../types/MediaObject";
import moment from "moment";

type ExternalReferenceFileProps = {
  referenceFileData: ExternalReferenceFileType;
};

export default function ExternalReferenceFile({ referenceFileData }: ExternalReferenceFileProps) {
  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState<MediaObject | null>(null);
  const downloadRefs = useRef(_.map(referenceFileData.mediaObjects, () => createRef<HTMLAnchorElement>()));

  const handleDownloadAll = () => {
    for (let i = 0; i < downloadRefs.current.length; i++) {
      setTimeout(() => {
        // @ts-ignore
        downloadRefs.current[i].current.click();
      }, i * 250);
    }
  };

  const sortedMediaObjects = referenceFileData.mediaObjects.sort((a, b) => {
    const convertedSelectionDateA = moment(a.selectionDate).unix();
    const convertedSelectionDateB = moment(b.selectionDate).unix();
    const convertedCreatedDateA = moment(a.created).unix();
    const convertedCreatedDateB = moment(b.created).unix();

    if (convertedSelectionDateA < convertedSelectionDateB) {
      return 1;
    }
    if (convertedSelectionDateA > convertedSelectionDateB) {
      return -1;
    }
    if (convertedCreatedDateA < convertedCreatedDateB) {
      return 1;
    }
    if (convertedCreatedDateA > convertedCreatedDateB) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button variant={"contained"} onClick={handleDownloadAll} fullWidth={false}>
          Alle Dokumente Herunterladen
        </Button>
      </Stack>
      <ContentBox headline={"Dokumente"}>
        {_.map(sortedMediaObjects, (mediaObject, index: number) => (
          <Box sx={{ margin: "1rem 0" }} key={mediaObject.id}>
            <ReferenceFileMediaObject
              mediaObject={mediaObject}
              setMediaObjectToDisplay={setMediaObjectToDisplay}
              downloadRef={downloadRefs.current[index]}
            />
          </Box>
        ))}
        <DisplayMediaObjects
          setMediaObjectToDisplay={setMediaObjectToDisplay}
          mediaObjects={sortedMediaObjects}
          mediaObjectToDisplay={mediaObjectToDisplay}
          refreshMediaObjects={() => {}}
          titleIsEditable={false}
        />
      </ContentBox>
    </>
  );
}

import { MediaObject } from "../types/MediaObject";
import { ReferenceFile } from "../types/ReferenceFile";
import moment from "moment";
import ApiClient from "./ApiClient";

export const getMediaObjectIdsFromSelectedMediaObjects = (selectedMediaObjects: ReferenceFile["selectedMediaObjects"]) => {

  if (isOldStructureSelectedMediaObjects(selectedMediaObjects)) {
    return selectedMediaObjects as unknown as Array<number>;
  }

  return selectedMediaObjects.map(mediaObject => mediaObject.id);
};
const augmentSelectedMediaObjectsWithSelectionDate = (initialSelectedMediaObjects: ReferenceFile["selectedMediaObjects"], currentSelectedMediaObjectIds: Array<MediaObject["id"]>) => {
  let convertedSelectedMediaObjects = currentSelectedMediaObjectIds.map(mediaObjectId => ({ id: mediaObjectId, selectionDate: moment().toISOString()}))

  if (isOldStructureSelectedMediaObjects(initialSelectedMediaObjects)) {
    return convertedSelectedMediaObjects;
  }

  convertedSelectedMediaObjects.forEach(currentSelectedMediaObject => {
    const previousEntry = initialSelectedMediaObjects.find(initialSelectedMediaObject => initialSelectedMediaObject.id === currentSelectedMediaObject.id);
    if (previousEntry) {
      currentSelectedMediaObject.selectionDate = previousEntry.selectionDate;
    }
  });
  return convertedSelectedMediaObjects;
};

export const saveSelectedMediaObjects = async (referenceFile: ReferenceFile, selectedMediaObjects: Array<MediaObject["id"]>) => {
  await ApiClient.put(referenceFile["@id"], {
    body: JSON.stringify({
      selectedMediaObjects: augmentSelectedMediaObjectsWithSelectionDate(referenceFile.selectedMediaObjects, selectedMediaObjects),
    }),
  });
};

//todo remove fallback handling for old structure after 2025
const isOldStructureSelectedMediaObjects = (selectedMediaObjects: any) => {
  return selectedMediaObjects.some((item: any) => typeof item === "number");
};

import * as stagesCase from "../../Stages/StagesCase";
import { AbstractCase } from "../../../types/AbstractCase";
import { ProductSpecificFormDefinition } from "../../../types/ProductService/ProductService";

const eventProductSpecificDefinition = (product: AbstractCase): ReturnType<ProductSpecificFormDefinition> => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "cancelled" }, { value: "cancellationRefused" }, { value: "other" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.eventType`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "private" }, { value: "public" }],
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.eventTitle`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.eventCity`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.eventDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorNumberField",
    path: `${product.productClassName}.numberOfTickets`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_LAWSUIT,
  },
];

export default eventProductSpecificDefinition;

import React from "react";
import caseAlertsStyle from "./caseAlertsStyle";
import { Box } from "@mui/material";
import { CaseStatus } from "../../../types/BackofficeCase";
import { AbstractCase } from "../../../types/AbstractCase";
import { STATUS_CHECKED as STATUS_CHECKED_TRAFFIC } from "../../../services/LeadStatus/StatusTraffic";
import { STATUS_CHECKED as STATUS_CHECKED_BASIC } from "../../../services/LeadStatus/StatusBasicCase";
import { STATUS_CHECKED as STATUS_CHECKED_DIVORCE } from "../../../services/LeadStatus/StatusDivorce";
import { STATUS_CHECKED as STATUS_CHECKED_SETTLEMENT } from "../../../services/LeadStatus/StatusSettlement";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { BackofficeUser, Roles } from "../../../types/BackofficeUser";
import { userHasRole } from "../../../services/backofficeUserService";

const hasMissingPoa = (product: AbstractCase, currentUser: BackofficeUser) => {
  // external users should not be able to view status
  if (userHasRole(currentUser, Roles.external)) {
    return false;
  }

  switch (product.productClassName) {
    case "traffic":
      return (
        product.leadStatus >= STATUS_CHECKED_TRAFFIC &&
        product.backofficeCase.status === CaseStatus.open &&
        product.powerOfAttorney === null
      );
    case "divorce":
      return (
        product.leadStatus >= STATUS_CHECKED_DIVORCE &&
        product.backofficeCase.status === CaseStatus.open &&
        product.powerOfAttorney === null
      );
    case "settlement":
      return (
        product.leadStatus >= STATUS_CHECKED_SETTLEMENT &&
        product.backofficeCase.status === CaseStatus.open &&
        product.powerOfAttorney === null
      );
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "dataLeakContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
    case "rentalContract":
      return (
        product.leadStatus >= STATUS_CHECKED_BASIC &&
        product.backofficeCase.status === CaseStatus.open &&
        product.powerOfAttorney === null &&
        (product.caseCategory === "defendClaim" || product.caseCategory === "enforceClaim")
      );
    default:
      return false;
  }
};

export default function CaseAlerts({ product }: { product: AbstractCase }) {
  const currentUser = useCurrentUser();

  return (
    <>
      {hasMissingPoa(product, currentUser) && (
        <Box sx={caseAlertsStyle.caseAlert}>Achtung: Keine gültige Vollmacht am Fall vorhanden</Box>
      )}
      {product.preventAllSystemEmails && (
        <Box sx={caseAlertsStyle.caseAlert}>
          Achtung: Automatische E-Mails und Nachrichten an die Mandantschaft sind pausiert!
        </Box>
      )}
      {product.backofficeCase?.preventAllSystemBeaOrFax && (
        <Box sx={caseAlertsStyle.caseAlert}>Achtung: Automatischer beA-/Fax-Versand ist pausiert!</Box>
      )}
      {product.preventAccounting && (
        <Box sx={caseAlertsStyle.caseAlert}>Achtung: Automatische Kontoeinträge/Rechnungen sind pausiert!</Box>
      )}
      {product.backofficeCase?.preventAllSystemActivities && (
        <Box sx={caseAlertsStyle.caseAlert}>Achtung: Automatische Aktivitäten sind pausiert!</Box>
      )}
    </>
  );
}
